import React from "react"

const { BgImage } = require("./bgstyle")
const { SrcSetImage } = require("./srcset")
const { PictureSrc } = require("./pic-src")

// eslint-disable-next-line consistent-return
export const ShowProcessedImage = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  switch (props.renderer) {
    case "bgImg":
      return <BgImage props={props} />
    case "pic-src":
      return <PictureSrc props={props} />
    default:
      return <SrcSetImage props={props} />
  }
}
