import React from "react"

const formImagePicture = (params) => {
  let imageSrc = ""
  return (
    <picture>
      {Object.keys(params.transforms).map((size) => {
        imageSrc = params.images[size]
        // eslint-disable-next-line react/jsx-key
        return <source srcSet={imageSrc} media={params.transforms[size]} />
      })}
      <img
        src={imageSrc}
        alt="image place"
        loading="lazy"
        className={params.className}
        {...params.attr}
      />
    </picture>
  )
}

export const PictureSrc = ({ props }) => {
  if (props.images) return formImagePicture(props)
  return ""
}
