import { isBrowser } from "./utility"
import { ProcessRequest } from "./axios.service"

const SERVER_BASE_URL = process.env.GATSBY_STRAPI_SRC
const SERVER_AUTH_TOKEN =
  process.env.GATSBY_STRAPI_API_TOKEN || process.env.GATSBY_STRAPI_FORM_TOKEN
const SERVER_API_BASE_ROUTE =
  process.env.GATSBY_SERVER_API_BASE_ROUTE || "stb-jamstack-ggfxserver-v3"

const PerformAction = (params) => {
  console.log("PerformAction GATSBY_STRAPI_SRC", SERVER_BASE_URL)
  return ProcessRequest({
    headers: {
      Authorization: `Bearer ${SERVER_AUTH_TOKEN}`,
    },
    baseURL: SERVER_BASE_URL || "STRAPI_URL",
    ...params,
  })
}

export const GetTransformedUrl = (params) => {
  if (!params.format)
    // eslint-disable-next-line no-nested-ternary, no-param-reassign
    params.format = isBrowser()
      ? localStorage.getItem("GGFX-NOTWEBP")
        ? ""
        : "webp"
      : ""
  return PerformAction({
    method: "get",
    url: `/${SERVER_API_BASE_ROUTE}/url/transform`,
    params,
  })
}
